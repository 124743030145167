import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// lazy-loaded
const Home = () => import("../views/HomeView.vue");
const Contributions = () => import("../views/Contributions.vue");

const Login = () => import("../views/Login.vue");
const Register = () => import("../views/Register.vue");

import PageNotFound from "../components/PageNotFound.vue";

// auth
//import Login from "../components/auth/Login.vue";
//const Login = () => import("../components/auth/Login.vue");
//import Register from "../components/auth/Register.vue";
//const Register = () => import("../components/auth/Register.vue");
//import VerifyAccount from "../components/auth/VerifyAccount.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/contributions",
    name: "contributions",
    component: Contributions,
  },
  {
    path: "/login",
    name: "authPage",
    component: Login,
    props: (route) => ({ message: route.query.message }),
  },
  {
    path: "/logout",
    name: "logoutPage",
    //redirect: "/",
    component: Home,
  },
  {
    path: "/signup",
    component: Register,
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ["/login", "/signup", "/signup/verify"];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = sessionStorage.getItem("auth");

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) next({ name: "authPage" });
//   else next();
// });

router.beforeEach((to, from, next) => {
  const publicPages = ["/logout"];
  if (publicPages.includes(to.path)) {
    localStorage.removeItem("user");
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
